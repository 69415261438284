import React from 'react';
import { Suspense } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Login from "./Login";
import Logout from "./Logout";
import Dash from "./Dashboard/Dashboard";
import ProductGroupListPage from "./ProductGroups/ProductGroupListPage";
import ProductListPage from "./Products/ProductListPage";
import ProductDetail from "./Products/ProductDetails";
import MovementListPage from "./Movements/MovementsListPage";
//import CreateInventoryDocumentPage from "./Documents/CreateInventoryDocumentPage";
import CreateDocumentPage from "./Movements/newdoc";
import UserSettings from "./Settings/UserSettings";
import Suppliers from "./Suppliers/SuppliersListPage";
import SupplierForm from "./Suppliers/Components/SupplierForm";
import SupplierDetail from "./Suppliers/SupplierDetails";
import {InventoryMovementType} from "../types/InventoryMovement";
import ProductStockTable from "./Warehouses/ProductStockTable";
import WarehouseDetails from "./Warehouses/WarehouseDetails";
import ProductGroupForm from "./ProductGroups/ProductGroupForm";
import SettingsPage from "./Settings/SettingsPage";
import ClassificationListPage from "./Classification/classificationListPage";
import ClassificationForm from "./Classification/classificationForm";
import MaterialForm from "./Materials/materialForm";
import MaterialListPage from "./Materials/materialListPage";


interface Props {
    component?: React.ComponentType | null
    path?: string | null
}

export const Routing = () => {
    
    

    return (
        <Suspense fallback={<div>Loading...</div>}>
   <BrowserRouter>
            <Switch>
               
                <Route key={1} exact path="/login" component={Login} />
                <Route key={0} exact path="/logout" component={Logout} />
                <Route key={21} exact path="/" component={Dash} />
                <Route key={21} exact path="/dashboard" component={Dash} />


                <Route key={31} exact path="/products" component={ProductListPage} />
                
                
                <Route key={32} exact path="/groups" component={ProductGroupListPage} />
                <Route key={321} exact path="/groups/new" component={ProductGroupForm} />
                <Route key={322} path="/groups/edit/:id" component={ProductGroupForm} />



                <Route key={42} exact path="/products/new" component={ProductDetail} />
                <Route key={43} exact path="/products/:id" component={ProductDetail} />

                <Route key={51} exact path="/stockmovement/newdoc" component={CreateDocumentPage} />
                <Route key={52} exact path="/stockmovement/newdoc/:docType" component={CreateDocumentPage} />
                <Route key={53} exact path="/stockmovement/:id/:docType" component={CreateDocumentPage} />
                <Route key={54} exact path="/stockmovement" component={MovementListPage} />


                <Route key={61} exact path="/stocks" component={ProductStockTable} />
                <Route key={62}  path="/stocks/create" component={WarehouseDetails} />
                <Route key={63} path="/stocks/edit/:id" component={WarehouseDetails} />
                
                
                

                <Route key={71} exact path="/settings" component={SettingsPage} />
                <Route key={72} exact path="/settings/suppliers" component={Suppliers} />
                <Route key={73} exact path="/settings/suppliers/new" component={SupplierDetail} />
                <Route key={74} path="/settings/suppliers/edit/:id" component={SupplierDetail} />

                <Route key={82} exact path="/classifications" component={ClassificationListPage} />
                <Route key={821} exact path="/classifications/new" component={ClassificationForm} />
                <Route key={822} path="/classifications/edit/:id" component={ClassificationForm} />

                <Route key={92} exact path="/materials" component={MaterialListPage} />
                <Route key={921} exact path="/materials/new" component={MaterialForm} />
                <Route key={922} path="/materials/edit/:id" component={MaterialForm} />



                {/*
                <Route key={4} exact path="/docs" component={DocList} />
                <Route key={41} exact path="/docs/:id" component={MovementListPage} />
                <Route key={42} exact path="/docs/received/:id/:status" component={DisplayReceived} />

*/}



            </Switch>
   </BrowserRouter>
        </Suspense>
        
    );
};