// src/pages/ProductGroupListPage.tsx
import React, { useState, useEffect } from 'react';
import {Button, Col, Input, Row, Table} from 'antd';
import { Product } from '../../types/Product';
import { productService } from '../../Services/productService';
import { productColumns } from './productColumns';
import {useHistory} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {useTranslation} from "react-i18next";

const ProductGroupListPage: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    const [searchText, setSearchText] = useState("")
    
    const {t}=useTranslation();


    const history=useHistory()

    useEffect(() => {
        const fetchProductGroups = async () => {
            try {
                const data = await productService.getProductsWithStockInfo();
                console.log("data: ", data)
                setProducts(data);
            } catch (error) {
                console.error('Failed to fetch product groups:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProductGroups();
    }, []);


    const productCols: ColumnsType<Product> = [

        {
            title: t('Code'),
            dataIndex: 'code',
            key: 'code',
            filteredValue :[searchText],
            onFilter: (value, record) => {
                //console.log("value: ", value)
                return (
                    String(record.name).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.code).toLowerCase().includes(value.toString().toLowerCase())||
                    String(record.description).toLowerCase().includes(value.toString().toLowerCase())

                )
            },

        },
        {
            title: t('Naziv'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record)=>{
                return <div>
                    {text}<br/>
                    <span style={{color: "#999"}}>{record.description}</span>
                </div>

            }
        },
        {
            title: t('Total_Stock'),
            dataIndex: 'totalStock',
            key: 'totalQuantity',
            align: "right",
            render: (text: number, record) => <span>{record.totalQuantity}</span>,
        },
        {
            title: t('Manufacturer'),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            // render: (text: number) => <span>{text}</span>,
        },
        {
            title: t('Product_group'),
            dataIndex: 'productGroupName',
            key: 'productGroupName',
            //render: (text: number) => <span>{text}</span>,
        },
        {
            title: 'EAN',
            dataIndex: 'ean',
            key: 'ean',
        },
    ];


    const { Search } = Input;


    return (
        <div>
            <h1>{t("Izdelki")}</h1>
            <Row gutter={24}>

                <Col span={4}>
                    <Search
                        style={{width: "100%"}}
                        onChange={(v)=>setSearchText(v.target.value)}
                        // addonAfter={"search"} 
                        placeholder={t("Išči")}/>
                </Col>
                <Col span={4}>
                    <Button className={"OrangeButton"} onClick={()=>{history.push("/products/new")}}>{t("add_new_product")}</Button>
                </Col>

            </Row>




            <Table
                columns={productCols}
                dataSource={products}
                rowKey="id"
                loading={loading}
                pagination={false}
                scroll={{y: 'calc(60vh)'}}

                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {history.push("/products/"+record.id)}, 
                        // click row
                    };
                }}

            />
        </div>
    );
};

export default ProductGroupListPage;
