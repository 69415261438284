// src/services/productGroupService.ts
import axios from 'axios';
import { ProductGroup } from '../types/ProductGroup';

import {DATA_URL} from './auth-service'
import authHeader from "./auth-header";
import {Classification} from "../types/Classification";

const API_URL = DATA_URL+'classification';

export const classificationService = {
    // Получение всех групп продуктов
    async getAll(): Promise<Classification[]> {
        const response = await axios.get<Classification[]>(API_URL, {headers: authHeader()});
        return response.data;
    },

    // Получение группы продуктов по ID
    async getById(id: string): Promise<Classification> {
        const response = await axios.get<Classification>(`${API_URL}/${id}`, {headers: authHeader()});
        return response.data;
    },

    // Создание новой группы продуктов
    async create(classification: Classification): Promise<Classification> {
        const response = await axios.post<Classification>(API_URL, classification, {headers: authHeader()});
        return response.data;
    },

    // Обновление существующей группы продуктов
    async update(id: string, classification: Classification): Promise<void> {
        await axios.put(`${API_URL}/${id}`, classification, {headers: authHeader()});
    },

    // Удаление группы продуктов
    async delete(id: string): Promise<void> {
        await axios.delete(`${API_URL}/${id}`, {headers: authHeader()});
    }
};
