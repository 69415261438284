import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Divider, Dropdown, Menu, MenuProps, Space} from "antd";
import {
    HomeOutlined,
    LogoutOutlined,
    SettingOutlined,
    TableOutlined,
    UserOutlined,
    ReadOutlined,
    TagsOutlined,
    CodeSandboxOutlined,
    TruckOutlined,
    SwapOutlined, SmileOutlined, ArrowRightOutlined
} from "@ant-design/icons";

import * as AuthService from "../Services/auth-service";
import {getCurrentUser} from "../Services/auth-service";



const HeadMenu = ()=> {

    const {t} = useTranslation();
    const history = useHistory();


    const locationString = window.location;

    const [current, setCurrent] = useState('/'+locationString.pathname.split('/')[1]);
    //console.log(locationString.pathname.split('/')[1])
    

    const user = getCurrentUser();
    //console.log(user)
    if(!user){
        history.push("/")
    }

    
    const itm=[
        {key: 2, icon:

                <div style={{color: "#fff"}}>
                    <Space>
                <span style={{cursor: "pointer"}} onClick={()=>{history.push("/settings/usersettings")}}>
                    <UserOutlined/> {user.firstName+" "+user.lastName}
                </span>

                        <Divider type={"vertical"} style={{color: "#fff"}}/>

                        <LogoutOutlined style={{cursor: "pointer"}}
                                        onClick={()=> {
                                            AuthService.logout();
                                            history.push("/logout")
                                        }}
                        />
                    </Space>

                </div>        
        }
    ];

    const items = [

        {key: "/dashboard", label: t("Domov"), icon: <HomeOutlined /> },
        {key: "/stockmovement", label: t("Movements"), icon: <SwapOutlined /> },
        {key: "/stocks", label: t("Stocks"), icon: <TableOutlined /> },
        {key: "/products", label: t("Izdelki"), icon: <CodeSandboxOutlined /> },
        
        {key: "/groups", label: t("Product_groups"), icon: <TagsOutlined />, 
            children: [
        {key: "/classifications", label: t("Classification"), icon: ""},
        {key: "/groups", label: t("Product_groups"), icon: ""},
        {key: "/materials", label: t("Type_Of_Material"), icon: ""}
    ]

},
        
        {key: "/settings/suppliers", label: t("Suppliers"), icon: <TruckOutlined /> },
        /*{key: 3, label: "settings", icon: <SettingOutlined/>,
        children: [
            {key: "/classifications", label: t("Classification"), icon: ""},
            {key: "/groups", label: t("Product_groups"), icon: ""},
            {key: "/Materials", label: t("Type_Of_Material"), icon: ""}
        ]
        },
        
         */
    ]





  
    

    function handleClick(e: any) {
        setCurrent(e.key);
    }
    
    return   <div style={{width: "100%"}}>
        
        <div style={{ display: "inline-block", float: "left", minWidth: "70%"
        }}>
        
        <div>
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[current]}
                items={items}
                style={{ flex: 1, minWidth: 0 }}
                onClick={(key) => {
                    handleClick(key);
                    history.push(key.key);
                }}
            />


        </div>
        
        </div>
        


        
        <div style={{color: "#fff", display: "inline-block", float: "right"}}>
            <Space>
                <span style={{cursor: "pointer"}} onClick={()=>{history.push("/settings")}}>
                    <SettingOutlined/> {user.firstName+" "+user.lastName}
                </span>
                
                <Divider type={"vertical"} style={{color: "#fff"}}/>
                
                <LogoutOutlined style={{cursor: "pointer"}} 
                                onClick={()=> {
                                    AuthService.logout();
                                    history.push("/logout")
                                }}
                />
            </Space>

        </div>
        
    </div>
    
    
    
}


export default HeadMenu